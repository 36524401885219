import React from 'react';
import Showcase from '../../Showcase/Showcase.js';

import '../../../styles/scss/style.scss'

export const Hero = () => {

  return (
    <div className="py-5" style={{minHeight:"calc(100vh - 85px)"}}>
      <div  className="container">
        <div className="hero">
          <div className="feature__wrap">
            <div className="feature__left">
              <h2 className="feature__lead feature__lead--2">
                Contrast Bootstrap 5 PRO UI Kit & Multipurpose Template
              </h2>
              <p className="feature__text">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
              </p>
            
              <div className="feature__buttons">
              
                <a className="btn1 btn__inverse" target="_blank" rel="noreferrer" href="https://cdbootstrap-demo.netlify.app/">
                  <span className="btn__icon">
                    <svg
                      width="22"
                      height="20"
                      viewBox="0 0 22 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M16.0457 9.82757L8.34869 6.14804C8.03574 5.99838 7.68104 6.04213 7.44706 6.25908C7.21308 6.47602 7.14486 6.82459 7.27377 7.14506L8.51447 10.2331L7.56331 13.2171C7.46745 13.5179 7.56066 13.8628 7.80049 14.1026C7.8076 14.1097 7.81479 14.1168 7.82212 14.1237C8.07788 14.3639 8.43819 14.4416 8.73836 14.3212L16.1186 11.3601C16.4159 11.2407 16.5977 10.9495 16.5819 10.6176C16.566 10.2857 16.3555 9.97559 16.0457 9.82757ZM9.74123 12.054L10.2056 10.5971C10.2632 10.4165 10.2536 10.2154 10.1784 10.0285L9.57293 8.52147L13.6673 10.4788L9.74123 12.054Z"
                        fill="#FFF"
                      />
                      <path
                        d="M10.9994 0C4.93431 0.00010632 0 4.48605 0 9.99989C0 15.5138 4.93431 19.9999 10.9995 20C17.0651 19.9999 21.9998 15.5139 21.9998 10C21.9998 4.48594 17.065 0 10.9994 0ZM10.9994 18.4051C5.90162 18.405 1.75416 14.6345 1.75416 9.99979C1.75416 5.36531 5.9015 1.59479 10.9994 1.59469C16.0977 1.59469 20.2454 5.36521 20.2454 9.99989C20.2455 14.6345 16.0977 18.405 10.9994 18.4051Z"
                        fill="#FFF"
                      />
                    </svg>
                  </span>
                  <span className="btn__text">Preview Pro</span>
                </a>
              </div>
            </div>
            <div className="feature__right">
              <img
                src={require('../../../components/images/aboutherofree.png')}
                alt="#"
                className="feature__img"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
