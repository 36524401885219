import React from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';

import '../../../styles/scss/style.scss'

export const VideoSection = () => {
  return (
    <div className="text-light py-5" style={{ minHeight: '100vh', background: '#3329e0' }}>
      <div className="container">
        <div className="feature-3 mt-5">
          <div className="feature__wrap">
            <div className="mx-auto text-center">
              <h1 className="font-bold text-4xl mb-3 mx-auto" style={{ maxWidth: '800px' }}>
                Create landing pages and admin dashboards for your projects in minutes
              </h1>
              <p className="mb-4 mx-auto" style={{ maxWidth: '600px' }}>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor
                incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam
              </p>
              <div className="flex item-center justify-center mb-5 mx-auto">
                <a target="_blank" href="https://cdbootstrap-demo.netlify.app/" rel="noreferrer">
                  <button className="btn1 btn__inverse mr-4">Preview</button>
                </a>
                <AnchorLink href="#pricing">
                  <button className="btn1 btn__inverse1">Download</button>
                </AnchorLink>
              </div>
              {/* <p className="mb-3 mx-auto"><small>There’s no way to describe it. You just have to see it for yourself.</small></p> */}
              <div>
                <video className="w-100 mx-auto" style={{ maxWidth: '920px' }} controls>
                  <source src={require('../../../components/images/demo1.mp4')} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
