import React from 'react';
import Helmet from 'react-helmet';

import AppContext, { UserContext } from "../components/AppContext";
import NavBar from "../components/NavBar/NavBar";
import { Footer } from "../components/Footer/Footer";
import { VideoSection } from '../components/LandingPage/Landing/VideoSection';
import { Hero } from '../components/LandingPage/Landing/Hero';
import { Showcase } from '../components/LandingPage/Landing/Showcase';
import { DocsPreview } from '../components/LandingPage/Landing/DocsPreview';
import { Pricing } from '../components/LandingPage/Landing/Pricing';

const AdminContrastPro = () => {

  return (
    <AppContext>
			<Helmet>
				<title>Devwares - Tools and resources for developers and designers </title>
				<meta name="title" content="Devwares - Tools and resources for developers and designers" />
				<meta
					name="description"
					content="We are creating high quality tools and resources to aid developers and designers during the development of their projects"
				/>
				<meta
					property="og:title"
					content="Devwares - Tools and resources for developers and designers "
				/>

				<meta
					property="og:description"
					content="We are creating high quality tools and resources to aid developers and designers during the development of their projects"
				/>
				<meta
					property="twitter:title"
					content="Devwares - Tools and resources for developers and designers"
				/>
				<meta
					property="twitter:description"
					content="We are creating high quality tools and resources to aid developers and designers during the development of their projects"
				/>
				<link rel="canonical" href="https://www.devwares.com/" />
				<meta property="og:url" content="https://www.devwares.com/" />
				<meta property="og:site_name" content="Devwares" />
				<meta name="twitter:card" content="summary_large_image" />
			</Helmet>
      <UserContext.Consumer>
      {({ user }) => (
      	<div>
    			<div className="container">
				 <NavBar user={user}/>
					</div>
					<Hero />
					<VideoSection />
					<DocsPreview />
					<Showcase />
					<Pricing />
    			<div className="container">
						<Footer />
					</div>
				</div>
			)}
      </UserContext.Consumer>
    </AppContext>
  );
};

export default AdminContrastPro;
