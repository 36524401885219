import React, { Fragment } from 'react';

import '../../../styles/scss/style.scss'

export const Pricing = () => {
  return (
    <Fragment>
      <div className="container" style={{ marginTop: '10rem' }} id="pricing">
        <div className="comparison">
          <div className="comparison__title">
            <h2 className="comparison__lead">Choose the Best Plan for you</h2>
            <p className="comparison__sub-title text-center">
              <br />{' '}
              <strong>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor
                incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam
              </strong>
            </p>
          </div>
          <div className="comparison__wrap" style={{ padding: '0rem 0.5%' }}>
            <div className="comparison__box">
              <div className="comparison__price">$49</div>
              <h2 className="comparison__package-name">Personal</h2>
              <p>Great for personal use and for your side projects.</p>
              <ul className="comparison__list2">
                <li className="good">Personal License</li>
                <li className="good">Full documentation</li>
                <li className="good">2000+ Components</li>
                <li className="good">10+ example Pages</li>
                <li className="good">Multilevel Sidebar</li>
                <li className="good">Advanced Dashboards</li>
                <li className="good">About Pages</li>
                <li className="good">Chat Page</li>
                <li className="good">Lifetime Premium Support</li>
                <li className="good">Lifetime Free Updates</li>
                <li className="good">VIP Early access</li>
              </ul>
              <a
                href="https://gum.co/SOGBj"
                data-gumroad-single-product="true"
                className="btn1 btn__inverse btn__full"
              >
                Pick for Personal use
              </a>
            </div>
            <div className="comparison__box">
              <div className="comparison__price">$109</div>
              <h2 className="comparison__package-name">Startup</h2>
              <p>Great for Startup Company/Projects </p>
              <ul className="comparison__list2">
                <li className="good">Team License</li>
                <li className="good">Team size: 1 - 10</li>
                <li className="good">Full documentation</li>
                <li className="good">2000+ Components</li>
                <li className="good">10+ example Pages</li>
                <li className="good">Multilevel Sidebar</li>
                <li className="good">Advanced Dashboards</li>
                <li className="good">About Pages</li>
                <li className="good">Chat Page</li>
                <li className="good">Lifetime Premium Support</li>
                <li className="good">Lifetime Free Updates</li>
                <li className="good">VIP Early access</li>
              </ul>
              <a
                href="https://gum.co/SOGBj"
                data-gumroad-single-product="true"
                className="btn1 btn__inverse btn__full"
              >
                Grab this
              </a>
            </div>
            <div className="comparison__box">
              <div className="comparison__price">$319</div>
              <h2 className="comparison__package-name">Enterprise</h2>
              <p>Best for large scale uses and extended redistribution rights.</p>
              <ul className="comparison__list2">
                <li className="good">Small Business License</li>
                <li className="good">Team size: Unlimited</li>
                <li className="good">Full documentation</li>
                <li className="good">2000+ Components</li>
                <li className="good">10+ example Pages</li>
                <li className="good">Multilevel Sidebar</li>
                <li className="good">Advanced Dashboards</li>
                <li className="good">About Pages</li>
                <li className="good">Chat Page</li>
                <li className="good">Lifetime Premium Support</li>
                <li className="good">Lifetime Unlimited Updates</li>
                <li className="good">VIP Early access</li>
              </ul>
              <a
                href="https://gum.co/SOGBj"
                data-gumroad-single-product="true"
                className="btn1 btn__inverse btn__full"
              >
                Grab this
              </a>
            </div>
          </div>
        </div>
        <p className="comparison__footer-info">* Secured payment through Gumroad</p>
      </div>
    </Fragment>
  );
};
